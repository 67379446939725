@import '../../styles/colors';

.submit-btn {
  background-color: darken($blue, 25);
  display: inline-block;
  padding: 0.35em 1.2em;
  border: none;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.2s;
}

.submit-btn:hover:enabled {
  background-color: darken($blue, 5);
}

.submit-btn:disabled {
  background-color: grey;
}

.form-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $darkblue;
  color: $darkblue;
  border-radius: 0;
}

.form-control:focus {
  background-color: transparent;
}

.modal-footer {
  border-top: none;
}