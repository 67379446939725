.banner {
  display: block;
  width: 100%;
}

.parallax-banner-layer-0 {
  background-position: top center !important;
  z-index: 2;
}


/* Extra extra small devices (iPhone SE) */
@media only screen and (max-width: 350px) {
  .parallax-banner {
    height: 400px !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 350px) {
  .parallax-banner {
    height: 450px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .parallax-banner {
    height: 500px !important;
  }
}

/* Large devices and above */
@media only screen and (min-width: 992px) {
  .parallax-banner {
    height: 95vh !important;
  }

  #App {
    margin-bottom: -20vh;
  }
}

/* Large devices and above */
@media only screen and (min-width: 1200px) {
  .parallax-banner {
    height: 90vh !important;
  }

  #App {
    height: 30vh;
    margin-bottom: -20vh;
  }
}
