@import './colors';

/* Extra extra small devices (phones, 600px and down) */
@media only screen and (max-width: 350px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 20px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 350px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 25px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 50px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 600px) and (orientation: landscape) {
  h1 {
    font-size: 50px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 15px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 60px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 12px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 70px;
  }

  h2 {
    font-size: 40px;
  }

  h4 {
    font-size: 19px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 12px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 50px;
  }

  h4 {
    font-size: 19px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 50px;
  }

  h4 {
    font-size: 19px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
}


/* Extra extra large devices*/
@media only screen and (min-width: 1600px) {
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 65px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 23px;
  }

  h6 {
    font-size: 18px;
  }
}