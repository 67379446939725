@import 'src/styles/colors';

#about {
  padding: 40px 0 0 0;
}

#about h2 {
  padding-bottom: 20px;
  font-family: 'Titillium Web', sans-serif;
  color: $darkblue;
}

#about h4 {
  padding-bottom: 10px;
}

#about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-image {
  position: absolute;;
  object-fit: cover;
  z-index: 2;
}

#about-image-frame {
  margin: 50px 0 60px 0;
  position: relative;
}


/* Medium devices and below */
@media only screen and (max-width: 992px) {
  #about-image-frame, #about-image {
    width: 150px;
    height: 200px;
  }
}

/* Large devices and up */
@media only screen and (min-width: 992px) {
  #about-image-frame, #about-image {
    width: 250px;
    height: 300px;
  }
}

#about-image-frame:after, #about-image-frame:before {
  mix-blend-mode: multiply;
  z-index: 1;
  content: '';
  width: calc(100% + (50px * 2));
  height: calc(100% + (50px * 2));
  position: absolute;
  display: block;
  animation: border 10s ease-in-out infinite;
  transform: translateX(-50px) translateY(-50px);
}

@keyframes border {
  0%, 100% {
    -webkit-clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
    clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
  }
  50% {
    -webkit-clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
    clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
  }
}

#about-image-frame:after {
  animation-delay: -5s;
  background-color: darken(#B6CA43, 10);
  clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
}

#about-image-frame:before {
  background-color: darken($skyblue, 10);
  clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
}