@import 'src/styles/global';

.timeline-item {
  margin-bottom: 10px;
}

a {
  color: $darkblue;
}

a:hover {
  text-decoration: none;
}

.title-link:hover {
  color: lighten($blue, 5);
}

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}


/* Extra extra small devices (phones, 600px and down) */
@media only screen and (max-width: 350px) {
  .learn-more {
    width: 11rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 350px) {
  .learn-more {
    width: 15rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .learn-more {
    width: 17rem;
  }
}


/* Extra extra large devices*/
@media only screen and (min-width: 1600px) {
  .learn-more {
    width: 21rem;
  }
}


button {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;

  &.learn-more {
    height: auto;

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      display: block;
      margin: 0;
      width: 2rem;
      height: 2rem;
      background: $darkblue;
      border-radius: 1.625rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: white;

        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
          left: 0.125rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;

          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: absolute;
      top: -0.2rem;
      left: 0.75rem;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $darkblue;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &:hover {
    .circle {
      width: 100%;

      .icon {
        &.arrow {
          background: white;
          transform: translate(1rem, 0);
        }
      }
    }

    .button-text {
      color: white;
    }
  }
}
